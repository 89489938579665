import React from 'react';
import './Card.css';
import { Card, Space, Button, Row, Col } from 'antd';
//import { Col, Row } from 'antd';
import { Divider } from 'antd';
//import sbermarket from '../../assets/img/sbermarket.png';
//import { Collapse } from 'antd';
//import { CaretRightOutlined } from '@ant-design/icons';
import Accordion from './CastomAccordion';
import { EntriesType } from '../types';

//import Description from './CardDescription';

//const { Panel } = Collapse;

const textLeftStyle: React.CSSProperties = {
    color: '#6A6E7E',
    fontSize: 16, 
    marginTop: 0,
    marginBottom: 0,
    textAlign: 'start',
    lineHeight: '24px'
};

const textRightStyle: React.CSSProperties = {
    color: '#4D5163', 
    marginTop: 0,
    marginBottom: 0,
    fontSize: 16,
    textAlign: 'end',
    lineHeight: '24px'
};



const CardItem: React.FC<EntriesType> = (props) => {
    let pathImg = '';
    props.icon.path[0] === '/' ? pathImg = `http://srochnoszaim.ru${props.icon.path}` :
    pathImg = `http://srochnoszaim.ru/${props.icon.path}`;
    return (
        <Card className='card_item'>
            <Row>
                <Col style={{width: '239px', height: '32px'}}>
                <img src={pathImg} alt="icon_company"
                    style={{maxWidth: '100%', height: '100%', objectFit: 'contain'}}/>
                {/* <img src={sbermarket} alt='img-sber'/> */}
                </Col>
            </Row>
            <Row>
                <Col
                style={{
                fontWeight: 600,
                fontSize: 16,
                lineHeight: '24px',
                color: "#4D5163", 
                margin: '8px 0 0',
                height: '24px',
                }}>
                <p className='text_overflow_ellipsis'>{props.name}</p>
                    
                {/* Курьер-сборщик на авто */}
            </Col>
            </Row>
            <Row>
                <Col
                    style={{ color: '#595D6F', margin: 0 }}>
                        {props.company.name}
                    {/* СберМаркет */}
                </Col>
            </Row>
            <Space direction='vertical' style={{marginTop: '16px', width: '100%'}} className='card_grid'>
                <Row justify="space-between">
                    <Col style={textLeftStyle}>
                        Оплата:
                    </Col>
                    <Col style={{
                        fontWeight: 600,
                        ...textRightStyle 
                        }}>
                            {props.wage_description.includes('₽') ? props.wage_description : `${props.wage_description} ₽`}
                        {/* до 126 000 &#x20bd; */}
                    </Col>
                </Row>
                <Row justify="space-between" style={{height: '24px'}}>
                    <Col flex="124px" style={{...textLeftStyle}}>
                        Тип занятости:
                    </Col>
                    <Col style={{...textRightStyle, height: '24px', width: 'calc(100% - 124px)'}}>
                        <p className='text_overflow_ellipsis'>
                            {props.tags.length > 1 ? props.tags.map(x => x.name).join(', ') : props.tags[0].name}
                        </p>
                            {/* Частичная занятость */}
                    </Col>
                </Row>
            </Space>
            <Divider style={{margin: '16px 0 12px 0'}}/> { /*  снизу 12px, когда описание не открыто */}
            <Space size={16} direction='vertical' style={{ width: '100%' }}>
                <Accordion {...props} />
                <Button type="primary" danger block
                    style={{fontSize: 16, height: '44px'}}
                >Откликнуться</Button>
            </Space>
        </Card>
    )
}

export default CardItem;