import React from 'react';
import { Space, Row, Col } from 'antd';
import { EntriesType } from '../types';

// const textLeftStyle: React.CSSProperties = {
//     color: '#6A6E7E', 
//     margin: 0,
//     fontSize: "16px",
//     lineHeight: '24px'
// };

const textAlignRightStyle: React.CSSProperties = {
    textAlign: 'end'
}

// const textRightStyle: React.CSSProperties = {
//     color: '#4D5163', 
//     margin: 0,
//     fontSize: '16px',
//     lineHeight: '24px'
// };

const Description: React.FC<EntriesType> = (props) => {  //описание карточки в пункте "Подробнее"
    return (
        <Space size={16} direction="vertical" className='card_description'>
            <Row>
                <Col span={12} className="card_description__leftText">
                    Тип оплаты:
                </Col>
                <Col span={12} style={textAlignRightStyle} className="card_description__rightText">
                    ????
                </Col>
            </Row>
            <Row>
                <Col span={12} className="card_description__leftText">
                    График работы:
                </Col>
                <Col span={12} style={textAlignRightStyle} className="card_description__rightText">
                    {props.schedules.length > 1 ? props.schedules.map(x => x.name).join(', ') : props.schedules[0].name}
                </Col>
            </Row>
            <Row gutter={[0, 2]}>
                <Col span={24} className="card_description__leftText">
                    Описание вакансии:
                </Col>
                <Col span={24} className="card_description__rightText" dangerouslySetInnerHTML={{ __html: props.description }}>
                    {/* {props.excerpt} */}
                </Col>  
            </Row>
            <Row>
                <Col span={12} className="card_description__leftText">
                    Требования:
                </Col>
                <Col span={12} style={textAlignRightStyle} className="card_description__rightText">
                    {props.age ? props.age : 'Нет'}
                </Col>
            </Row>
            <Row gutter={[0, 2]}>
                
                    <Col span={24} className="card_description__leftText">
                        Обязанности:
                    </Col>
                
                    <Col span={24} 
                        className="card_description__rightText" 
                        dangerouslySetInnerHTML={{ __html: props.excerpt.replace(/Обязанности:/g,'') }}>
                            {/* {props.description} */}
                    </Col> 
                
            </Row>
            <Row gutter={[0, 2]}>
                    <Col span={24} className="card_description__leftText">
                        Города:
                    </Col>
                
                    <Col span={24} className="card_description__rightText">
                        {props.geo.length > 1 ? props.geo.map(x => x.name).join(', ') : props.geo[0].name}
                    </Col>
     
            </Row>
        </Space>
    )
}

export default Description;