import React, { useState, useEffect } from "react";
import { Space, Spin, Pagination } from "antd";
import axios from "axios";
// import axios, { AxiosError } from 'axios';
import { OfferType } from "./types";
import CardItem from "./Card/Card";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setAllOffers } from "./StateOfferSlice";


const CardWrapper: React.FC = () => {
    const [loading, setLoading] = useState(false);
    //const [offers, setOffers] = useState<OfferType | null>(null);
    const dispatch = useAppDispatch();
    const offerState = useAppSelector(state => state.offers.stateOffers);

    useEffect(() => {  //загрузка офферов по апи
        setLoading(true);
        const apiUrl = `https://srochnoszaim.ru/api/collections/get/offers?token=12d336be28cf878395bde971a2bda3&populate=true`;
        axios.get(apiUrl).then((resp) => {
          const allOffers: OfferType = resp.data;
          //setOffers(allOffers);
          setLoading(false);
          dispatch(setAllOffers(allOffers.entries))
        });
    }, [setLoading, dispatch]);

    // async function getOffers() {
    //     try {
    //         setLoading(true);
    //         const res = await axios.get(`https://srochnoszaim.ru/api/collections/get/offers?token=12d336be28cf878395bde971a2bda3&populate=true`);
    //         const allOffers: OfferType = res.data;
    //         setLoading(false);
    //         return allOffers;
    //     }
    //     catch (e: unknown) {
    //         const err = e as AxiosError
    //         console.error(err.message);
    //     }
    // }
    
    return (
        <>
        {!loading && offerState ? 
            <Space className='card_wrapper'>
                <ResponsiveMasonry
                    columnsCountBreakPoints={{375: 1, 768: 2, 1024: 3, 1919: 4}}>  
                    <Masonry
                        className='my-masonry-grid'
                        >
                            { offerState.map((offer, index) => (
                            <CardItem {...offer} key={index}/>
                            )) }
                    </Masonry>
                </ResponsiveMasonry>
                <Pagination defaultCurrent={1} total={offerState.length} />
            </Space>
        :
        <Space className="loading_screen">
            <Spin size="large" />
        </Space>
        }
        </>
    )
}

export default CardWrapper;
