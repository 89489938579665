import React, { useState } from 'react';
import Description from './CardDescription';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { EntriesType } from '../types';

const Accordion: React.FC<EntriesType> = (props) => {
    const [show, setShow] = useState<boolean>(false);
    const styleText: React.CSSProperties = {
        display: 'flex',
        gap: '7px',
        justifyContent: 'center',
        //textAlign: 'center',
        color: '#6A6E7E', 
        cursor: 'pointer', 
        margin: '3px 0'
    }

    return (
        <div>
            {!show ? 
            <div onClick={() => setShow(true)}
                style={styleText}
                className="more">
                    Подробнее
                    <DownOutlined/>
            </div> 
            :
            <div>
                <Description {...props}/>
                <div onClick={() => setShow(false)}
                style={styleText}
                className="more">
                    Свернуть
                    <UpOutlined />
                </div>
            </div>
            }
        </div>
    )
}

export default Accordion;