import React from 'react';
import { Footer } from 'antd/es/layout/layout';
import { Col, Row } from 'antd';
import telegram from '../assets/icons/telegram.png';

const FooterComponent: React.FC = () => {
    return (
        <Footer style={{backgroundColor: "#FFFFFF", padding: 0}}>
            <Row className='footer_row'>
                <Col style={{textAlign: 'start'}}>
                    <p style={{margin: 0, color: '#595D6F', lineHeight: '22px'}}>
                    Copyright © LEADS.SU 2010–2020.<br/>
                    Все права защищены.
                    </p>
                </Col>
                <Col style={{textAlign: 'end'}}>
                    <img src={telegram} alt='telegram'/>
                </Col>
            </Row>
        </Footer>
    )
}

export default FooterComponent;