import React from 'react';
import { Layout } from 'antd';
import Header from './Header';
import FooterComponent from './Footer';
import './HeaderFooter.css';
import CardWrapper from './CardWrapper';


// import { Grid, Row, Col } from "react-flexbox-grid";


const Wrapper: React.FC = () => {
    return (
        <Layout className='wrapper' style={{background: '#fff', fontFamily: 'Montserrat, san-serif', width: '100%' }}>  
            <Header/>
            <CardWrapper/>
            <FooterComponent/>
        </Layout>
    )
}

export default Wrapper;