import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { EntriesType } from './types';

interface offerState {
    offers: EntriesType[],
    stateOffers: EntriesType[],
    filters: {
        position: string[],
        city: string[]
    }
}

const initialState: offerState = {
    offers: [],
    stateOffers: [],
    filters: {
        position: [],
        city: []
    }
}

function filter(offers: EntriesType[], filters: {position: string[], city: string[]}) {
    if (filters.position.length && !filters.city.length) {
        return offers.filter((offer) => filters.position.some(pos => pos.toLowerCase() === offer.name.toLowerCase()))
    }
    if (filters.city.length && !filters.position.length) {
        const filterArray = offers.filter((offer) => filters.city.some(city => {
            return offer.geo.some(geo => geo.name === city);
        }))
        return filterArray
    }
    if (filters.city.length && filters.position.length) {
        const filtPos = offers.filter((offer) => filters.position.some(pos => pos.toLowerCase() === offer.name.toLowerCase()));
        const filtCity = filtPos.filter((offer) => filters.city.some(city => {
            return offer.geo.some(geo => geo.name === city);
        }))
        return filtCity
    }
    else return offers
}

export const offerSlice = createSlice({
    name: 'offers',
    initialState,
    reducers: {
        setAllOffers: (state, action: PayloadAction<EntriesType[]>) => {
            state.offers = action.payload;
            state.stateOffers = action.payload;
            console.log('state:', current(state));
        },
        setStateFilterPosition: (state, action: PayloadAction<string[]>) => {
            state.filters.position = action.payload;
            const dataOffer = current(state.offers);
            const dataFilter = current(state.filters);
            state.stateOffers = filter(dataOffer, dataFilter);
            console.log('stateFiltPos:', current(state));
        },
        setStateFilterCity: (state, action: PayloadAction<string[]>) => {
            state.filters.city = action.payload;
            const dataOffer = current(state.offers);
            const dataFilter = current(state.filters);
            state.stateOffers = filter(dataOffer, dataFilter);
            console.log('state:', current(state));
        },
    }
})

export const { setAllOffers, setStateFilterPosition, setStateFilterCity } = offerSlice.actions;

export const selectOfferState = (state: RootState) => state.offers;

export default offerSlice.reducer;