import { ConfigProvider } from 'antd';
import React from 'react';
import './App.css';
import Wrapper from './components/Wrapper';


const App: React.FC = () => {
  return (
    <ConfigProvider
      theme={{
        token: {   
            fontFamily: 'Montserrat, Ubuntu, san-serif',
            screenXS: 375,
            screenXSMax: 767,
            screenXSMin: 375,
            screenSM: 768,
            screenSMMax: 1023,
            screenSMMin: 768,
            screenMD: 1024,
            screenMDMax: 1365,
            screenMDMin: 1024,
            screenLG: 1366,
            screenLGMax: 1539,
            screenLGMin: 1366,
            screenXL: 1540,
            screenXLMax: 1919,
            screenXLMin: 1540,
            screenXXL: 1920,
            screenXXLMin: 1920,
        },
      }}
    >
    <Wrapper/>
    </ConfigProvider>
    
  );
}

export default App;
