import React from "react";
import { Row, Col, Space, Select } from 'antd';
import illustration from '../assets/img/illustration.png';
import logo from '../assets/icons/logo.png';
import telegram from '../assets/icons/telegram.png';
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setStateFilterCity, setStateFilterPosition } from "./StateOfferSlice";
//import { DownOutlined } from '@ant-design/icons';

// const optionsPosition: {value: string, label: string}[] = [
//     {
//       value: '1',
//       label: 'Курьер',
//     },
//     {
//       value: '2',
//       label: 'Доставщик',
//     },
//     {
//       value: '3',
//       label: 'Таксист',
//     },
//     {
//       value: '4',
//       label: 'Оператор колл-центра',
//     },
//     {
//       value: '5',
//       label: 'Кассир',
//     },
//     {
//       value: '6',
//       label: 'Повар',
//     },
//     {
//       value: '7',
//       label: 'Специалист по продажам',
//     },
//     {
//       value: '8',
//       label: 'Консультант',
//     },
// ];

// const optionsCity:  {value: string, label: string}[] = [
//     {
//       value: '1',
//       label: 'Москва',
//     },
//     {
//       value: '2',
//       label: 'Санкт-Петербург',
//     },
//     {
//       value: '3',
//       label: 'Казань',
//     },
//     {
//       value: '4',
//       label: 'Нижний Новгород',
//     },
//     {
//       value: '5',
//       label: 'Ульяновск',
//     },
//     {
//       value: '6',
//       label: 'Мурманск',
//     },
//     {
//       value: '7',
//       label: 'Самара',
//     },
//     {
//       value: '8',
//       label: 'Петрозаводск',
//     },
// ]

const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const stateOffers = useAppSelector(state => state.offers.offers); // массив офферов
  const positionsSet = new Set(stateOffers.map((item) => item.name)); //достаем из массива все должности и создаем сет с уникальными значениями
  const positions = Array.from(positionsSet);  // преобразуем сет в массив
  const optionsPosition = positions.map((item, index) => {  //создаем массив объектов вида {value: string, label: string}
    return {value: item, label: item}
  })

  const sityAr = stateOffers.map((item) => item.geo) // создаем массив, состоящий из массива с объектами городов
  const sityArr2 = sityAr.map((geoArray) => { // достаем названия городов
    if (geoArray.length > 1) {
      return geoArray.map(item => item.name)
    } else {
      return geoArray[0].name
    }
  });
  const sityUnique = Array.from(new Set(sityArr2.flat())); // массив уникальных значений
  const sitySort = sityUnique.sort()
  const optionsCity = sitySort.map((item, index) => {   //создаем массив объектов вида {value: string, label: string}
    return {value: item, label: item}
  })
  //console.log('geo', sityAr )

  const handleChangePosition = (value: string[]) => { // [курьер, кассир]
    console.log('selected', value);
    dispatch(setStateFilterPosition(value))
  };

  const handleChangeCity = (value: string[]) => { // [Москва, Рязань]
    console.log('selected', value); 
    dispatch(setStateFilterCity(value))
  };

    return (
        <>
        <Row>
                <Col span={24} className='header_first_row'>
                    <img src={logo} alt='logo' className='header_first_row__logo'/>
                    <img src={telegram} alt='telegram' className='header_first_row__telegram'/>
                </Col>
            </Row>
        <Row>
            <Col span={24} className='header_second_row'>
                <Space wrap size={0} style={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', width: '100%'}}>
                    <p className="header_second_row__title">
                        Работа найдётся для каждого
                    </p>
                    <p className="header_second_row__article">
                        Бесплатный сервис подбора вакансий без опыта работы
                    </p>
                    <Space wrap className="header_options">
                        <Select
                            //showSearch
                            showArrow
                            mode="multiple"
                            allowClear
                            placeholder="Должность"
                            onChange={handleChangePosition}
                            style={{ width: 395}}
                            className="header_options__option"
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                            options={optionsPosition}
                        />
                        <Select
                            showSearch
                            showArrow
                            mode="multiple"
                            allowClear
                            placeholder="Город"
                            onChange={handleChangeCity}
                            style={{ width: 395}}
                            className="header_options__option"
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                            options={optionsCity}
                        />
                    </Space>
                </Space>
                <Space>
                    <img src={illustration} alt='illustration' className="illustration"/>
                </Space>
            </Col>
        </Row>
        </>
    )
}

export default Header;